<template>
  <div class="set_lan_wrap">
    <!-- <div class="lan_item" v-for="(item, index) in verConfig.lans || lans" :key="index"
      :class="{ lan_selected: isSelected(item) }" @click="setLans(item)">
      <img :src="item.icon" class="lan_icon" />
      <div class="lan_title">{{ item.name }}</div>
    </div> -->
    <div class="lan_item" @click="showPicker = true">
      <img :src="require('@/i18n/icon/' + getLan + '.png')" class="lan_icon" />
      <div class="lan_title">{{ getValue(getLan).name }}</div>
    </div>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker show-toolbar :default-index="getValue(getLan).index" :confirm-button-text="$t('Button.confirm')" :cancel-button-text="$t('Button.cancle')"
        value-key="name" :columns="(verConfig.lans || lans)" @cancel="showPicker = false" @confirm="setLans" />
    </van-popup>
  </div>
</template>

<script>
import { getLanguage, lans, setLanguage } from "@/i18n";
import { Picker, Popup } from 'vant';
import verConfig from "@/utils/verConfig";
export default {
  components: {
    [Picker.name]: Picker,
    [Popup.name]: Popup,
  },
  props: {
    areaCode: {
      type: Number | String,
      default: verConfig.areaCode,
    },
  },
  model: {
    prop: "areaCode",
    event: "updateAreaCode"
  },
  data: () => ({
    lans,
    lanValue: "",
    showPicker: false
  }),
  computed: {
    getLan() {
      return getLanguage()
    }
  },
  created() {
    const lansArr = this.verConfig.lans || lans;
    this.lanValue = getLanguage();
  },
  methods: {
    getValue(value) {
      const obj = lans.findIndex(item => item.value == value);
      return {
        name: lans[obj].name,
        index: obj
      }
    },
    setLans(item) {
      if (item.value === this.lanValue) return;
      this.lanValue = item.value;
      setLanguage(item.value);
      location.reload();
    },
    isSelected(item) {
      if (this.lanValue === item.value) return true;
      return false;
    },
  },
};
</script>

<style lang="less" scoped>
.set_lan_wrap {
  // position: absolute;
  // top: 6px;
  // right: 6px;
  // width: calc(100rem / 16);
  width: fit-content;
  border-radius: 6px;
  overflow: hidden;

  .lan_item {
    display: flex;
    align-items: center;
    padding: calc(6rem / 16);

    >img {
      width: calc(20rem / 16);
      height: calc(20rem / 16);
      border-radius: 50%;
      flex-shrink: 0;
    }

    .lan_title {
      width: 100%;
      margin-left: calc(6rem / 16);
      font-size: calc(16rem / 16);
      // max-width: 60px;
      color: var(--btnsizeColor);
    }
  }
}

/deep/ .van-picker__toolbar {
  .van-picker__cancel {
    color: var(--sizeColor);
  }

  .van-picker__confirm {
    color: var(--theme);
  }
}

</style>